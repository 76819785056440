export class B2bEditPlugin {
    addConfigFieldsToQuery = (args, callback) => [
        'sp_disable_b2b_edit',
        ...callback(args)
    ];

    addStateToProps(args, callback) {
        const [state] = args;
        const { sp_disable_b2b_edit } = state.ConfigReducer;
        return {
            ...callback(...args),
            disableB2bEdit: sp_disable_b2b_edit
        };
    }

    addToContainerProps(args, callback, instance) {
        const { disableB2bEdit } = instance.props;
        return {
            ...callback(args),
            disableB2bEdit
        };
    }

    hideElementOnB2b(args, callback, instance) {
        const { disableB2bEdit } = instance.props;
        return disableB2bEdit ? null : callback(args);
    }
}

const {
    addConfigFieldsToQuery,
    addStateToProps,
    addToContainerProps,
    hideElementOnB2b
} = new B2bEditPlugin();

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: addConfigFieldsToQuery
        }
    },
    'Component/MyAccountAddressTable/Container/mapStateToProps': {
        function: addStateToProps
    },
    'Component/MyAccountAddressTable/Container': {
        'member-function': {
            containerProps: addToContainerProps
        }
    },
    'Component/MyAccountAddressTable/Component': {
        'member-function': {
            renderActions: hideElementOnB2b
        }
    },
    'Component/MyAccountAddressBook/Container/mapStateToProps': {
        function: addStateToProps
    },
    'Component/MyAccountAddressBook/Container': {
        'member-function': {
            containerProps: addToContainerProps
        }
    },
    'Component/MyAccountAddressBook/Component': {
        'member-function': {
            renderActions: hideElementOnB2b
        }
    },
    'Component/MyAccountCustomerTable/Container/mapStateToProps': {
        function: addStateToProps
    },
    'Component/MyAccountCustomerTable/Container': {
        'member-function': {
            containerProps: addToContainerProps
        }
    },
    'Rapelli/Component/MyAccountCustomerTable/Component/MyAccountCustomerTableComponent': {
        'member-function': {
            renderEditButton: hideElementOnB2b
        }
    },
    'Component/MyAccountDashboard/Container/mapStateToProps': {
        function: addStateToProps
    },
    'Component/MyAccountDashboard/Container': {
        'member-function': {
            containerProps: addToContainerProps
        }
    },
    'Component/MyAccountDashboard/Component': {
        'member-function': {
            renderLinkToAddressBook: hideElementOnB2b
        }
    },
    'Rapelli/Component/CheckoutAddressBook/Component/CheckoutAddressBookComponent': {
        'member-function': {
            renderOptionalCustomAddress: hideElementOnB2b
        }
    },
    'Rapelli/Component/CheckoutAddressBook/Container/mapStateToProps': {
        function: addStateToProps
    },
    'Rapelli/Component/CheckoutAddressBook/Container/CheckoutAddressBookContainer': {
        'member-function': {
            containerProps: addToContainerProps
        }
    }
};
